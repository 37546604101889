<template>
    <el-form ref="form" :model="form" :rules="rules">
        <div class="grid grid-cols-12 gap-4 px-5 relative">
            <div class="col-span-3 px-2">
                <el-form-item class="w-full" prop="status">
                    <label class="text-gray-400 mb-0 block">帳號狀態</label>
                    <el-select
                        v-model="form.status"
                        class="w-full"
                        placeholder="請選擇狀態"
                    >
                        <el-option
                            v-for="(option, index) in statusOptions"
                            :key="index"
                            :label="option.label"
                            :value="option.value"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item class="w-full" prop="plan">
                    <label class="text-gray-400 mb-0 block">方案</label>
                    <el-select
                        v-model="form.plan"
                        class="w-full"
                        placeholder="請選擇狀態"
                    >
                        <el-option
                            v-for="(option, index) in planOptions"
                            :key="index"
                            :label="option.label"
                            :value="option.value"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
            </div>
            <div class="col-span-9 px-2">
                <div class="grid grid-cols-2 md:gap-4">
                    <el-form-item
                        class="w-full md:col-span-1 col-span-2 mb-3"
                        prop="login"
                    >
                        <label class="text-gray-400 mb-0 leading-none">
                            登入帳號
                            <el-input
                                v-model="form.login"
                                class="w-full"
                                maxlength="10"
                                @input="
                                    form.login = form.login.replace(
                                        /[^\a-\z\A-\Z0-9]/g,
                                        ''
                                    )
                                "
                            />
                        </label>
                    </el-form-item>
                    <el-form-item
                        class="w-full md:col-span-1 col-span-2 mb-3"
                        prop="password"
                    >
                        <label class="text-gray-400 mb-0 leading-none">
                            密碼
                            <div class="relative">
                                <el-input
                                    v-model="form.password"
                                    :type="!showPassword ? 'password' : 'text'"
                                    class="w-full"
                                    @input="
                                        form.password = form.password.replace(
                                            /[^\a-\z\A-\Z0-9]/g,
                                            ''
                                        )
                                    "
                                />
                                <!-- 眼睛開關 用來顯示密碼使用 -->
                                <span
                                    class="absolute right-2 top-1 cursor-pointer text-xl"
                                    @click="showPassword = !showPassword"
                                >
                                    <i
                                        v-if="showPassword"
                                        class="icofont-eye-alt"
                                    ></i>
                                    <i
                                        v-if="!showPassword"
                                        class="icofont-eye-blocked"
                                    ></i>
                                </span>
                            </div>
                        </label>
                    </el-form-item>
                </div>
                <div class="grid grid-cols-2 md:gap-4">
                    <el-form-item
                        class="w-full md:col-span-1 col-span-2 mb-3"
                        prop="name"
                    >
                        <label class="text-gray-400 mb-0 leading-none">
                            介紹人 / 公司名稱
                            <el-input
                                v-model="form.name"
                                class="w-full"
                            ></el-input>
                        </label>
                    </el-form-item>
                    <el-form-item
                        class="w-full md:col-span-1 col-span-2 mb-3"
                        prop="representative"
                    >
                        <label class="text-gray-400 mb-0 leading-none">
                            真實姓名 / 代表人
                            <el-input
                                v-model="form.representative"
                                class="w-full"
                            ></el-input>
                        </label>
                    </el-form-item>
                </div>
                <div class="grid grid-cols-2 md:gap-4">
                    <div class="w-full md:col-span-1 col-span-2 mb-3">
                        <el-form-item prop="email">
                            <label class="text-gray-400 mb-0 leading-none">
                                Email
                                <el-input
                                    v-model="form.email"
                                    placeholder="請輸入電子郵件"
                                    class="w-full"
                                />
                            </label>
                        </el-form-item>
                        <!-- <el-checkbox-group v-model="form.marketing_notification">
                            <el-checkbox class="text-gray-300 leading-none" :label="0"
                                >我不想收到&nbsp;City&nbsp;Banana&nbsp;相關的行銷資訊</el-checkbox
                            >
                        </el-checkbox-group> -->
                    </div>
                    <el-form-item prop="government_cert">
                        <label class="text-gray-400 mb-0 block">證件型態</label>
                        <div class="flex">
                            <el-select
                                v-model="form.government_cert"
                                class="rounded-tr-none rounded-none max-w-[120px]"
                                placeholder="證件型態"
                            >
                                <el-option
                                    v-for="(item, index) in certOptions"
                                    :key="index"
                                    :label="item.label"
                                    :value="item.value"
                                >
                                </el-option>
                            </el-select>
                            <el-form-item class="flex-1" prop="government_id">
                                <el-input
                                    v-model="form.government_id"
                                    placeholder="請輸入身份證"
                                    class="w-full"
                                />
                            </el-form-item>
                        </div>
                    </el-form-item>
                </div>
                <div class="grid grid-cols-2 md:gap-4">
                    <div class="w-full md:col-span-1 col-span-2 mb-3">
                        <el-form-item prop="phone">
                            <label class="text-gray-400 mb-0 leading-none">
                                聯絡電話
                                <el-input
                                    v-model="form.phone"
                                    placeholder="請輸入聯絡電話"
                                    class="w-full"
                                />
                            </label>
                        </el-form-item>
                    </div>
                </div>
                <el-form
                    ref="form"
                    :model="form"
                    :rules="rules"
                    @submit.native.prevent
                >
                    <span class="block text-gray-400 text-sm mb-3 leading-none"
                        >客服註記</span
                    >
                    <Remarks :datas="supportLogs" />
                    <el-form-item class="w-full">
                        <div v-loading="loading" class="flex items-end">
                            <label class="flex-1">
                                <el-input
                                    v-model="inputNote"
                                    class="w-full"
                                    placeholder="請輸入註記內容"
                                >
                                </el-input>
                            </label>
                            <btn
                                v-permission="['update']"
                                color="border-red-500 border text-red-500"
                                size="md:text-sm text-xs px-2 py-2 rounded max-w-[150px]"
                                customClass="ml-2"
                                @onClick="updatFeebackLog"
                            >
                                送出註記
                            </btn>
                        </div>
                    </el-form-item>
                </el-form>
            </div>
        </div>
        <div class="flex justify-center mt-10">
            <btn v-permission="['update']" @onClick="updateIntroducer">
                資料更新
            </btn>
        </div>
    </el-form>
</template>

<script>
import Remarks from "@/components/Remarks.vue";
import { areas } from "@/langs/tw.json";
import { brokerPlan } from "@/config/introducerConfig.js";

const defaultInput = {
    login: "",
    status: "",
    password: "",
    name: "",
    plan: "",
    representative: "",
    email: "",
    government_id: "",
    government_cert: "",
    phone: "",
    district: "",
    address: "",
    marketing_notification: [],
};
// 導入自定義 按鈕
import Btn from "@/components/Button.vue";
export default {
    name: "Profile",
    components: {
        Btn,
        Remarks,
    },
    computed: {
        // 帳號狀態 options
        statusOptions() {
            return [
                {
                    label: "使用中",
                    value: 0,
                },
                {
                    label: "停用",
                    value: -1,
                },
            ];
        },
        // 身份認證類別選擇
        certOptions() {
            return [
                {
                    label: "身份證",
                    value: 0,
                },
                {
                    label: "護照",
                    value: 1,
                },
                {
                    label: "居留證",
                    value: 2,
                },
                {
                    label: "統一編號",
                    value: 3,
                },
            ];
        },
        // 使用語系檔取得地區資料
        areaOptions() {
            return areas;
        },
        // 方案
        planOptions() {
            const option = [];
            for (const i in brokerPlan) {
                option.push({
                    label: brokerPlan[i],
                    value: parseInt(i),
                });
            }
            return option;
        },
        rules() {
            return {
                status: [
                    {
                        required: true,
                        message: "必填",
                        trigger: "blur",
                    },
                ],
                login: [
                    {
                        required: true,
                        message: "必填",
                        trigger: "blur",
                    },
                ],
                password: [
                    {
                        min: 6,
                        message: "需6字以上",
                        trigger: "blur",
                    },
                    {
                        required: false,
                        pattern: /^(?=.*[a-zA-Z])(?=.*\d)[a-zA-Z\d]{6,18}$/,
                        message: "需為 6-18 位數字，且至少包含一個英文",
                        trigger: "blur",
                    },
                ],
                name: [
                    {
                        required: true,
                        message: "必填",
                        trigger: "blur",
                    },
                ],
                plan: [
                    {
                        required: true,
                        message: "必填",
                        trigger: "blur",
                    },
                ],
                representative: [
                    {
                        required: true,
                        message: "必填",
                        trigger: "blur",
                    },
                ],
                phone: [
                    {
                        required: true,
                        message: "必填",
                        trigger: "blur",
                    },
                    {
                        pattern: /^[8869]{4}[0-9]{8}$/,
                        message: `手機格式錯誤`,
                        trigger: "blur",
                    },
                ],
                address: [
                    {
                        required: false,
                        message: "必填",
                        trigger: "blur",
                    },
                ],
                district: [
                    {
                        required: false,
                        message: "必填",
                        trigger: ["blur"],
                    },
                ],
                email: [
                    { required: true, message: "必填", trigger: "blur" },
                    {
                        type: "email",
                        message: "錯誤的電子郵件格式",
                        trigger: ["blur", "change"],
                    },
                    {
                        required: true,
                        validator: this.formEmailCheck,
                        trigger: "blur",
                    },
                ],
                government_id: [
                    {
                        required: true,
                        message: "必填",
                        trigger: "blur",
                    },
                    {
                        // 統一編號為8碼 護照9碼
                        min:
                            this.form.government_cert == 3
                                ? 8
                                : this.form.government_cert === 1
                                ? 9
                                : 10,
                        max:
                            this.form.government_cert == 3
                                ? 8
                                : this.form.government_cert === 1
                                ? 9
                                : 10,
                        message: "證件號碼長度不府合",
                        trigger: "blur",
                    },
                    // 身份證字號驗證
                    // { required: true, pattern: /^[A-Z][12][0-9]{8}$/, message: "請輸入正確的身份證字號", trigger: "blur" }
                ],
                government_cert: [
                    {
                        required: true,
                        message: "必填",
                        trigger: "blur",
                    },
                ],
            };
        },
    },
    data() {
        return {
            showPassword: false,
            loading: false,
            supportLogs: [],
            form: {
                ...defaultInput,
            },

            sendNoteloading: false,
            // 註記相關
            inputNote: "",
            inputSignificant: false, // 重點註記輸入匡
            showSignificant: false,
        };
    },
    methods: {
        getBrokerData() {
            this.$api.GetSingleBrokerApi(this.id).then((res) => {
                this.form = { ...res.data };
            });
        },
        getSupportLog() {
            this.$api
                .GetBrokerSupportLog(this.id, { limit: 30 })
                .then((res) => {
                    this.supportLogs = [...res.data.data];
                })
                .catch((err) => {
                    this.$message({
                        type: "warning",
                        message: "取得資料失敗",
                    });
                    console.log(err);
                });
        },
        /**
         * 更新使用者
         */
        async updateIntroducer() {
            const result = await this.$refs["form"].validate();
            if (result) {
                this.$api
                    .UpdateSingleBrokerApi(this.id, this.form)
                    .then((res) => {
                        this.$message({
                            type: "success",
                            message: "更新成功",
                        });
                        this.form = { ...res.data.broker };
                        this.updateBread(this.form);
                    })
                    .catch((err) => {
                        this.$message({
                            type: "warning",
                            message: "更新失敗",
                        });
                        console.log(err);
                    })
                    .finally(() => {
                        this.$emit("update");
                    });
            }
        },
        /**
         * 發送客服註記
         * @param { type String (字串) } val 註記內容
         */
        updatFeebackLog() {
            this.loading = true;
            this.$api
                .CreateBrokerSupportLog(this.id, {
                    note: this.inputNote,
                    significant: this.inputSignificant ? 1 : 0,
                })
                .then(() => {
                    this.$message({
                        type: "success",
                        message: "更新成功",
                    });
                    this.getSupportLog();
                    this.inputNote = "";
                    this.inputSignificant = false;
                })
                .catch((err) => {
                    console.log(err);
                    const errMessage = err.response.data.error.message;
                    this.$message({
                        type: "error",
                        message: errMessage,
                    });
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        updateBread(info) {
            this.$store.commit("breadcrumbStore/setCrumbList", [
                {
                    meta: {
                        text: "用戶管理",
                    },
                },
                {
                    name: "introducer_list",
                    meta: {
                        text: "介紹人管理",
                    },
                    path: "#/user_list/introducer_list",
                },
                {
                    meta: {
                        text: info.name,
                    },
                },
                {
                    name: this.$route.name,
                    meta: {
                        text: "個人資料",
                    },
                },
            ]);
        },
    },
    mounted() {
        this.id = this.$route.params.id;
        this.getBrokerData();
        this.getSupportLog();
        this.$emit("updateBreadCrumb", "個人資料");
    },
};
</script>

<style lang="scss" scoped>
@import "@/scss/_text-style.scss";
.section-title {
    width: 80%;
    margin-bottom: 20px;
    @extend .text-black-blod-18;
}
.hasSign {
    background: #ff573324 !important;
}
</style>
